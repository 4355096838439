import React from "react";

// landing page is also the default landing
import LandingPage from "./LandingPage/LandingPage.jsx";

const ChilimaniaIndexPage = () => (
  <LandingPage/>
);

export default ChilimaniaIndexPage;
